<template>
  <WaitingMobile v-if="isMobile()" />
  <WaitingDesktop v-else />
</template>

<script>
import { isMobile } from "@/libs/helpers";

export default {
  components: {
    WaitingDesktop: () => import("@/components/goods_receipt/WaitingDesktop"),
    WaitingMobile: () => import("@/components/goods_receipt/WaitingMobile"),
  },
  name: "Index",
  methods: {
    isMobile,
  },
};
</script>

<style scoped></style>
